/////////////// GSAP animation modules (Uncomment required gsap library!) /////////////////
// import gsap from "gsap";
// import ScrollTrigger from "gsap/ScrollTrigger"; gsap.registerPlugin(ScrollTrigger);
// import ScrollSmoother from "gsap/ScrollSmoother"; gsap.registerPlugin(ScrollSmoother);
// import DrawSVGPlugin from "gsap/DrawSVGPlugin"; gsap.registerPlugin(DrawSVGPlugin);
// import MorphSVG from "gsap/MorphSVGPlugin"; gsap.registerPlugin(MorphSVG);
// import SplitText from "gsap/SplitText"; gsap.registerPlugin(SplitText);
///////////////////////////////////////////////////////////////////////////////////////////

//// Module summary ////
// A) BUG FIX => Mobile menu not disappearing after navlink click
// B) Turn navbar color to blue when mobile menu is open

export const header = () => {
  ///////
  let navbar = document.querySelector('.navbar');
  if (navbar) {
    // A) BUG FIX => Mobile menu not disappearing after navlink click
    let mobileBtn = document.querySelector('.navbar1_menu-button');
    let mobileNavLinks = document.querySelectorAll('.navbar1_link');
    for (currentLink of mobileNavLinks) {
      currentLink.addEventListener('click', () => {
          setTimeout(() => {
            if (window.innerWidth < 992) { // ! Dirty but Webflow. 
              mobileBtn.click();
            }
          }, 400);
      });
    }

    // B) Turn navbar color to blue when mobile menu is open
    let blueInk = "#1b1641";
    navbar.addEventListener('click', e => {
      setTimeout(() => {
        if (navbar.querySelector('.w--open')) {
          navbar.style.backgroundColor = blueInk;
        } else {
          navbar.style.backgroundColor = "";
        }
      }, 50);
    });
  }
  return;
  ///////
}
