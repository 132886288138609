// * //////// *GSAP animation modules (Uncomment required gsap library!) //////////// * //
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
// import ScrollSmoother from "gsap/ScrollSmoother"; gsap.registerPlugin(ScrollSmoother);
// import DrawSVGPlugin from "gsap/DrawSVGPlugin"; gsap.registerPlugin(DrawSVGPlugin);
// import MorphSVG from "gsap/MorphSVGPlugin"; gsap.registerPlugin(MorphSVG);
// import SplitText from "gsap/SplitText"; gsap.registerPlugin(SplitText);
// * ////////////////////////////////////////////////////////////////////////////////// *//

// * Module summary * //
// GSAP animations for blog single page

export const blog = () => {
	let blogSection = document.querySelector(".blog-template");
	if (blogSection) {
		// Make the author and main photo appearing slightly later
		let author = document.querySelector(".date-author-box");
		let mainImg = document.querySelector(".featured-img-padding");
		gsap.to(author, {
			autoAlpha: 1,
			delay: .4,
		});

		gsap.to(mainImg, {
			autoAlpha: 1,
			delay: .6,
		});

		// Make a slight parallax effect on the header
		let header = document.querySelector(".article-header");
		let headerDefault = window.getComputedStyle(header).height;
		let headerContent = document.querySelector(".article-header-content");
		let headerBackground = document.querySelector(".article-header>img");
		
		let headerTrigger = {
			trigger: document.querySelector(".main-wrapper"),
			start: "top+=" + window.innerHeight + " bottom",
			end: "top+=" + window.innerHeight + " top",
			scrub: true,
			markers: false,
		};

		// Parallax
		// gsap.to(header, {
		// 	height: (parseInt(headerDefault) / 1.42) + "px",	
		// 	scrollTrigger: headerTrigger,
		// });

		// Header content opacity
		gsap.to(headerContent, {
			autoAlpha: 0,
			scrollTrigger: headerTrigger,
		});

		// Header background scale
		gsap.to(headerBackground, {
			scale: 1.1, 
			scrollTrigger: headerTrigger,
		});
	}

	return;
};
