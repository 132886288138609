//// JS MAIN FILE ////

// GSAP register
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
//import DrawSVGPlugin from "gsap/DrawSVGPlugin";
//import SplitText from "gsap/SplitText";

// Import Webflow
import { webflow } from './modules/_webflow.js';
webflow();

// Import modules
import { common } from './modules/_common.js';
common();
import { navbar } from './modules/_navbar.js';
navbar();

// Import gsap library
import { gsapLib } from './ink-gsap/_ink-gsap.js';
//gsapLib();
import { envTags } from './ink-gsap/_envTags.js';
envTags();

// Import templates
import { home } from './templates/_home.js';
home();
import { header } from './templates/_header.js';
header();
import { blog } from './templates/_blog.js';
blog();