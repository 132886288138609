/////////////// GSAP animation modules (Uncomment required gsap library!) /////////////////
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"; gsap.registerPlugin(ScrollTrigger);
import ScrollToPlugin from "gsap/ScrollToPlugin"; gsap.registerPlugin(ScrollToPlugin);
// import ScrollSmoother from "gsap/ScrollSmoother"; gsap.registerPlugin(ScrollSmoother);
// import DrawSVGPlugin from "gsap/DrawSVGPlugin"; gsap.registerPlugin(DrawSVGPlugin);
// import MorphSVG from "gsap/MorphSVGPlugin"; gsap.registerPlugin(MorphSVG);
// import SplitText from "gsap/SplitText"; gsap.registerPlugin(SplitText);
///////////////////////////////////////////////////////////////////////////////////////////

//// Module summary ////
// ** Explain here what this code does in the project ** // 
// JS code to be executed on the main page
// === A) Hero animation
// === B) Tracks animations
// === C) Portfolio grid 
// === D) Pieuvre 
// === E) Parallax titles

// === Z) Enable navbar everywhere else

export const home = () => {
    let navbar = document.querySelector(".navblur");
    // Hide the navbar on home load
    if (document.querySelector('#home')) {
        gsap.set(navbar, { y: "-100%" });
        // === A) Hero animation
        let gradient = document.querySelector(".gradient-bubble");
		let heroHeading = document.querySelectorAll('[data-gsap="hero-heading"]');

        let startAnim = () => {
            if (heroHeading.length > 0) {
                gsap.to(heroHeading, {
                y: 0,
                stagger: 0.2,
                ease: "power3.inOut",
                duration: 2,
                scale: 1,
                autoAlpha: 1
                });
            }
            const GRADANIM = gsap.timeline();
            GRADANIM.fromTo(
              gradient,
              {
                scale: 3
              },
              {
                scale: 1,
                autoAlpha: 0.85,
                duration: 1.5
              }
            ).addLabel('done')
            .fromTo(
              gradient,
              {
                scale: 1
              },
              {
                scrollTrigger: {
                  trigger: ".nav-bar-trigger",
                  start: "top top",
                  end: "bottom top",
                  scrub: 2,
                  onLeave: () => gsap.to(navbar, { y: "0", ease: "power2" }),
                  onEnterBack: () => gsap.to(navbar, { y: "-100%", ease: "power2" })
                },
                scale: 3
              }
            );
          
            if (window.location.hash) {
              let anchor = document.querySelector(window.location.hash);
              if (anchor) {
                gsap.to(window, { duration: 0, scrollTo: window.location.hash })
              }
            }
        };
		
		window.addEventListener("load", startAnim);

        // === B) Tracks animations
        let tracks = document.querySelectorAll(".track");
        if (tracks.length > 0) {
            gsap.registerEffect({
                name: "runningTrack",
                effect: (target, track) => {
                  // Si track est impair, on va vers la gauche, otherwise vers la droite
                  fromPercent = track % 2 != 0 ? 0 : -50;
                  toPercent = track % 2 != 0 ? -50 : 0;
                  duration = Math.floor(Math.random() * 18) + 42;
            
                  return gsap.fromTo(
                    target,
                    {
                      xPercent: fromPercent
                    },
                    {
                      xPercent: toPercent,
                      duration,
                      ease: "none",
                      repeat: -1
                    }
                  );
                }
              });
    
            // Tracks assignement
            let track = 0;
            for (let current of tracks) {
                track++;
                let titlesCopy = current.firstElementChild.cloneNode(true);
                current.append(titlesCopy);
                gsap.effects.runningTrack(current, track);
            }
        }

        // === C) Portfolio grid 
        let portfolio = document.querySelectorAll('.portfolio-grid .project-link');
        if (portfolio.length > 0) {
            gsap.from(portfolio, { 
            autoAlpha: 0,
            y: 100,
            duration: 1,
            stagger: .175,
            scrollTrigger: ".portfolio-grid",
            ease: "power3.out"
            });
        }

        // === D) Pieuvre 
        let pieuvre = document.querySelector('[data-gsap="pieuvre"]');
        if (pieuvre) {
          gsap.from(pieuvre, {
            autoAlpha: 0,
            scrollTrigger: {
              trigger: pieuvre,
              start: "top bottom",
              end: "bottom top",
              scrub: 2.5,
            }
          });
        }

        // === E) Parallax titles
        let parallax = document.querySelectorAll('.parallax');
        if (parallax.length > 0) {
            for (current of parallax) {
                gsap.fromTo(current, {
                  y: "40%",
                }, {
                  y: "-40%",
                  scrollTrigger: {
                    trigger: current,
                    start: "top bottom",
                    end: "top top",
                    scrub: 1,
                    markers: false
                  },
                });
            }
        }
    }
    return;
}
